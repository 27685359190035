import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
    afterNextRender,
    Component,
    effect,
    ElementRef,
    HostListener,
    Inject,
    inject,
    makeStateKey,
    PLATFORM_ID,
    signal,
    TransferState,
    ViewChild,
} from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { ItemMenu } from '../../../types/menu.type';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { MenuModule } from 'primeng/menu';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { BrowserStorageService } from '@services/global/browser.storage.service';
import { PageService } from '@services/page/page.service';
import { ValueGlobalService } from '@services/global/value.global.service';
import { Category } from '../../../types/categories.type';
import { LocalDefault } from '../../../types/locale.type';
import { AppsManagementResponse } from '../../../types/common.type';
import { ItemPage } from '../../../types/page.type';
import { definitions } from '@services/cms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-header',
    standalone: true,
    imports: [
        RouterLink,
        RouterLinkActive,
        FormsModule,
        DropdownModule,
        MenuModule,
        OverlayPanelModule,
        TranslateModule,
        CommonModule,
    ],
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss',
})
export class HeaderComponent {
    private router = inject(Router);
    private transferState = inject(TransferState);
    private translate = inject(TranslateService);

    @ViewChild('menuMobile') menuMobile!: ElementRef;

    readonly #browserStorageService = inject(BrowserStorageService);
    readonly #valueGlobalService = inject(ValueGlobalService);
    readonly #pageService = inject(PageService);
    readonly menuService = this.#valueGlobalService.menu;
    readonly localeDefaultService = this.#valueGlobalService.locale;

    menus = signal<definitions['do.CategoryDO']>(undefined);
    openMenuMobile: boolean = false;
    showScroll: boolean = false;
    currentPosition: any;
    ecosystems: ItemMenu[] = [
        {
            title: 'Govo Tech',
            link: '/govo-tech',
        },
        {
            title: 'Jacat Games',
            link: '/jacat-games',
        },
        {
            title: 'Fidra IOS',
            link: '/fidra',
        },
    ];

    selectedCountry = signal<LocalDefault>(undefined);
    countries = [
        {
            name: 'VNI',
            code: 'vi',
            icon: 'assets/image/pages/header/flag_vietnam.svg',
        },
        {
            name: 'ENG',
            code: 'en',
            icon: 'assets/image/pages/header/flag_en.svg',
        },
    ];

    localeDefault = signal<any>({});

    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        let pos =
            (document.documentElement.scrollTop || document.body.scrollTop) +
            document.documentElement.offsetHeight;
        if (pos > this.currentPosition) {
            this.showScroll = true;
        } else {
            this.showScroll = false;
        }
        this.currentPosition = pos;
    }

    constructor(@Inject(PLATFORM_ID) private platformId: Object) {
        afterNextRender({
            write: () => {
                this.selectedCountry.set(this.#valueGlobalService.locale());
            },
        });

        effect(() => {
            if (this.localeDefaultService()) {
            }
        });
        this.translate.setDefaultLang(
            this.localeDefaultService()?.code
                ? this.localeDefaultService()?.code
                : 'vi'
        );
    }

    ngOnInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.getPageGlobal();
        }
    }

    isLinkActive(link: string) {
        const url = this.router.url;
        return url.indexOf(link) >= 0;
    }

    openTabMenu() {
        this.openMenuMobile = !this.openMenuMobile;
        if (!!this.menuMobile) {
            if (this.menuMobile.nativeElement.style.left === '0px') {
                this.menuMobile.nativeElement.style.left = '-300px';
            } else {
                this.menuMobile.nativeElement.style.left = '0px';
            }
        }
    }

    closeTab() {
        this.openMenuMobile = false;
        this.menuMobile.nativeElement.style.left = '-300px';
    }

    onCountryChange(event: DropdownChangeEvent | any) {
        this.#browserStorageService.clear();

        let nameRoute = this.router.url;

        let checkActiveRouter;

        switch (nameRoute) {
            case '/':
                checkActiveRouter = '/en';
                break;
            case '/gioi-thieu':
                checkActiveRouter = '/introduce';
                break;
            case '/van-hoa':
                checkActiveRouter = '/culture';
                break;
            case '/su-kien':
                checkActiveRouter = '/event';
                break;
            case '/hoat-dong':
                checkActiveRouter = '/activity';
                break;
            case '/ecosystem/jacat-games':
                checkActiveRouter = '/ecosystem/jacat-games/en';
                break;
            case '/ecosystem/govo-tech':
                checkActiveRouter = '/ecosystem/govo-tech/en';
                break;
            case '/ecosystem/fidra':
                checkActiveRouter = '/ecosystem/fidra/en';
                break;
            case '/ecosystem/jacat-games/en':
                checkActiveRouter = '/ecosystem/jacat-games';
                break;
            case '/ecosystem/govo-tech/en':
                checkActiveRouter = '/ecosystem/govo-tech';
                break;
            case '/ecosystem/fidra/en':
                checkActiveRouter = '/ecosystem/fidra';
                break;
            case '/tuyen-dung':
                checkActiveRouter = '/recruit';
                break;
            case '/lien-he':
                checkActiveRouter = '/contact';
                break;
            case '/en':
                checkActiveRouter = '/';
                break;
            case '/introduce':
                checkActiveRouter = '/gioi-thieu';
                break;
            case '/culture':
                checkActiveRouter = '/van-hoa';
                break;
            case '/event':
                checkActiveRouter = '/su-kien';
                break;
            case '/activity':
                checkActiveRouter = '/hoat-dong';
                break;
            case '/recruit':
                checkActiveRouter = '/tuyen-dung';
                break;
            case '/contact':
                checkActiveRouter = '/lien-he';
                break;
        }

        if (event.value.code !== this.#valueGlobalService.locale().code) {
            if (
                nameRoute.indexOf('/recruit/') >= 0 ||
                nameRoute.indexOf('/tuyen-dung/') >= 0
            ) {
                if (event.value.code === 'vi') {
                    this.router.navigateByUrl('/');
                } else {
                    this.router.navigateByUrl('/en');
                }
            } else {
                if (checkActiveRouter) {
                    this.router.navigateByUrl(checkActiveRouter);
                } else {
                    if (event.value.code === 'vi') {
                        this.router.navigateByUrl('/');
                    } else {
                        this.router.navigateByUrl('/en');
                    }
                }
            }
        }

        this.#valueGlobalService.setLocaleDefault(event.value);

        this.#browserStorageService.setItem(
            'language',
            JSON.stringify(event.value)
        );

        this.selectedCountry.set(event.value);

        this.getPageGlobal();
        this.scrollToTop();

        this.translate.use(event.value.code);
    }

    scrollToTop() {
        (function smoothscroll() {
            var currentScroll =
                document.documentElement.scrollTop || document.body.scrollTop;
            if (currentScroll > 0) {
                window.requestAnimationFrame(smoothscroll);
                window.scrollTo(0, currentScroll - currentScroll / 5);
            }
        })();
    }

    getPageGlobal() {
        this.#pageService.getPage('global').subscribe((item) => {
            if (item?.data?.categories) {
                this.menus.set(
                    item.data.categories.find(
                        (category) => category.slug === 'menu'
                    )
                );

                let contact = item.data.categories.find(
                    (category) => category.slug === 'contact-info'
                );
                this.#valueGlobalService.setFooter(this.menus(), contact);
            }
        });
    }
}
