import { Component, effect, inject, signal } from '@angular/core';
import { ItemMenu } from '../../../types/menu.type';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MenuModule } from 'primeng/menu';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ValueGlobalService } from '@services/global/value.global.service';
import { Category } from '../../../types/categories.type';

@Component({
    selector: 'app-footer',
    standalone: true,
    imports: [
        CommonModule,
        RouterLink,
        RouterLinkActive,
        MenuModule,
        OverlayPanelModule,
    ],
    templateUrl: './footer.component.html',
    styleUrl: './footer.component.scss',
})
export class FooterComponent {
    private router = inject(Router);

    readonly #valueGlobalService = inject(ValueGlobalService);
    readonly menuService = this.#valueGlobalService.menu;
    readonly localeDefaultService = this.#valueGlobalService.locale;
    readonly infoContactService = this.#valueGlobalService.infoContact;

    ecosystems: ItemMenu[] = [
        {
            title: 'Govo Tech',
            link: '/govo-tech',
        },
        {
            title: 'Jacat Games',
            link: '/jacat-games',
        },
        {
            title: 'Fidra IOS',
            link: '/fidra',
        },
    ];

    constructor() {
        effect(() => {
            if (this.menuService()) {
            }
        });
    }

    isLinkActive(link: string) {
        const url = this.router.url;
        return url.indexOf(link) >= 0;
    }
}
